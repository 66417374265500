import React from 'react';
import NavigationBar from '../components/Navbar';
import Footer from '../components/Footer';
import { LanguageContext } from '../contexts/LanguageContext';
import map from '../images/map.webp';
import KontaktText from '../components/KontaktText';
import { Helmet } from 'react-helmet';
import EditableText from '../components/EditableText';

const KontaktPage: React.FC = () => {

  const { language } = React.useContext(LanguageContext);
  return (
    <div className='grips-bg d-flex flex-column min-vh-100'>
      {language === 'de' ?
      <Helmet>
        <title>Kontakt - meltshop365</title>
      </Helmet>
      :
      <Helmet>
        <title>Contact - meltshop365</title>
      </Helmet>
      }
      <NavigationBar />
      <div className="text-center flex-grow-1">
      {language === 'de' ?<h1 className="my-4 mt-5 text-white" id='305'><EditableText id='305'  isH1/></h1> : <h1 className="my-4 mt-5 text-white" id='306'><EditableText id='306'  isH1/></h1>}
        <div className="mx-auto my-4" style={{width: '100px', borderBottom: '10px solid red'}}></div>
        <KontaktText />
      </div>
      <div className="d-flex justify-content-center mt-5 mb-5">
        <img src={map} alt="grips location"  className='img-fluid'/>
      </div>
      <Footer />
    </div>
  );
}

export default KontaktPage;