import React from 'react';
import NavigationBar from '../components/Navbar';
import Footer from '../components/Footer';
import { LanguageContext } from '../contexts/LanguageContext';
import SytemText from '../components/SystemText';
import B7 from '../images/B7.webp';
import { Helmet } from 'react-helmet';
import EditableText from '../components/EditableText';

const SystemPage: React.FC = () => {

  const { language } = React.useContext(LanguageContext);
  return (
    <div className="d-flex flex-column min-vh-100">
      {language === 'de' ?
      <Helmet>
        <title>System - meltshop365 by GRIPS Industrial IT Solutions</title>
        <meta name="description" content="meltshop|365 basiert auf modernsten Technologien in der Softwareentwicklung und ist unter dem Betriebssystem Microsoft Windows ablauffähig." />
        <meta name="keywords" content="ERP, Industrie 4.0, Schmelz- und Gießbetrieb, Software, ERP-Software, Cloud" />
      </Helmet>
      :
      <Helmet>
        <title>System - meltshop365 by GRIPS Industrial IT Solutions</title>
        <meta name="description" content="meltshop|365 is based on state-of-the-art technologies in software development and executable with the operating system Microsoft Windows. The database system uses Microsoft SQL Server. meltshop|365 is built in client/server architecture. The server services are installed in the cloud or at your corporate network. The client applications are available in the browser or as Windows desktop applications." />
        <meta name="keywords" content="ERP, Industry 4.0, Melting and Casting Operation, Software, ERP Software, Cloud" />
      </Helmet>
    }
    <NavigationBar />
    <div className="bg-image d-flex flex-column justify-content-center align-items-center flex-grow-1">
      <div className="bg-white p-5 rounded mx-5 my-5 w-75">
      {language === 'de' ?<h1 className="my-4 mt-5 h1-grips" id='247'><EditableText id='247'  isH1/></h1> : <h1 className="my-4 mt-5 h1-grips" id='248'><EditableText id='248'  isH1/></h1>}
          {language === 'de' ?<h4 className='h4-grips' id='249'><EditableText id='249'  /></h4> : <h4 className='h4-grips' id='250'><EditableText id='250'  /></h4>}
        <div className="my-4" style={{width: '100px', borderBottom: '10px solid red'}}></div>
        <SytemText />
        <div className='d-flex justify-content-center'>
        <img src={B7} alt="EINSATZBERECHNUNG" className='img-fluid'/>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
}

export default SystemPage;