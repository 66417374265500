import React, { useState, useContext } from 'react';
import { Navbar, Nav, Dropdown } from 'react-bootstrap';
import { Container, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../images/meltshop365.svg';
import { LanguageContext } from '../contexts/LanguageContext';
import '../css/Navbar.css'; 
import { AuthContext } from '../contexts/LoginContext';

function NavigationBar() {
    const { language, setLanguage } = React.useContext(LanguageContext);
    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
    const [open, setOpen] = useState(false);

    const handleLogout = () => {
        setIsLoggedIn(false);
        localStorage.removeItem('token');
    };

    return (
        <Navbar expand='xl' className={`bg-body-tertiary ${window.innerWidth <= 1366 ? 'p-2' : 'p-5'}`}>
        <Container>
            <Navbar.Brand href="/">
                <img src={logo} alt="Logo" className={`d-inline-block align-top img-fluid ${window.innerWidth <= 1366 ? 'w-50' : 'w-75'}`} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className='me-auto'>
                    <Dropdown
                        onMouseEnter={() => setOpen(true)}
                        onMouseLeave={() => setOpen(false)}
                        show={open}
                        drop='down'
                        id="basic-nav-dropdown"
                        className={`custom-link ${window.innerWidth <= 1199 ? '' : 'd-flex justify-content-center'} align-items-center`}
                        >
                            <Dropdown.Toggle as={window.innerWidth <= 1366 ? 'div' : Nav.Link} href="/merkmale" className='custom-link'>
                                {language === 'de' ? 'MERKMALE' : 'FEATURES'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="/administration" className="outlined-dropdown-item dropdown-items">
                                    <p className='mb-1'>
                                    {language === 'de' ? 'ADMINISTRATION' : 'ADMINISTRATION'}
                                    </p>
                                </Dropdown.Item>
                                <Dropdown.Item href="/produktionsplanung" className="outlined-dropdown-item dropdown-items">
                                    <p className='mb-1'>
                                    {language === 'de' ? 'PRODUKTIONSPLANUNG' : 'PRODUCTIONPLANNING'}
                                    </p>
                                </Dropdown.Item>
                                <Dropdown.Item href="/rohstoffoptimierung" className="outlined-dropdown-item dropdown-items">
                                    <p className='mb-1'>
                                    {language === 'de' ? 'ROHSTOFFOPTIMIERUNG' : 'RAW MATERIAL OPTIMIZATION'}
                                    </p>
                                </Dropdown.Item>
                                <Dropdown.Item href="/materialwirtschaft" className="outlined-dropdown-item dropdown-items">
                                    <p className='mb-1'>
                                    {language === 'de' ? 'MATERIALWIRTSCHAFT' : 'MATERIAL MANAGEMENT'}
                                    </p>
                                </Dropdown.Item>
                                <Dropdown.Item href="/schmelzplanung" className="outlined-dropdown-item dropdown-items">
                                    <p className='mb-1'>
                                    {language === 'de' ? 'SCHMELZPLANUNG' : 'HEAT PLANNING'}
                                    </p>
                                </Dropdown.Item>
                                <Dropdown.Item href="/einsatzberechnung" className="outlined-dropdown-item dropdown-items">
                                    <p className='mb-1'>
                                    {language === 'de' ? 'EINSATZBERECHNUNG' : 'CHARGE CALCULATION'}
                                    </p>
                                </Dropdown.Item>
                                <Dropdown.Item href="/prozessautomatisierung" className="outlined-dropdown-item dropdown-items">
                                    <p className='mb-1'>
                                    {language === 'de' ? 'PROZESSAUTOMATISIERUNG' : 'PROCESS AUTOMATION'}
                                    </p>
                                </Dropdown.Item>
                                <Dropdown.Item href="/controlling" className="outlined-dropdown-item dropdown-items">
                                    <p className='mb-1'>
                                    {language === 'de' ? 'CONTROLLING' : 'CONTROLLING'}
                                    </p>
                                </Dropdown.Item>
                                <Dropdown.Item href="/auftragsabrechnung" className="outlined-dropdown-item dropdown-items">
                                    <p className='mb-1'>
                                    {language === 'de' ? 'AUFTRAGSABRECHNUNG' : 'ORDER ACCOUNTING'}
                                    </p>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    <Nav.Link href="/system" className="custom-link">
                        {language === 'de' ? 'SYSTEM' : 'SYSTEM'}
                    </Nav.Link>
                    <Nav.Link href="/processvars" className="custom-link">
                        {language === 'de' ? 'PROCESSVARS' : 'PROCESSVARS'}
                    </Nav.Link>
                    <Nav.Link href="/lizenzierung" className="custom-link">
                        {language === 'de' ? 'LIZENSIERUNG' : 'LICENSING'}
                    </Nav.Link>
                    <Nav.Link href="/kontakt" className="custom-link">
                        {language === 'de' ? 'KONTAKT' : 'CONTACT'}
                    </Nav.Link>
                    {/*<Nav.Link href="/news" className="custom-link">
                        {language === 'de' ? 'NEWS' : 'NEWS'}
                    </Nav.Link>'*/}
                </Nav>
                <Nav className="d-flex flex-row">
                    <Nav.Link href="/" onClick={(e) => { e.preventDefault(); setLanguage('de'); }} className={`language-selector ${language === 'de' ? 'font-weight-bold' : ''}`}>de</Nav.Link>
                    <div className="border-right mx-2 pt-2">&zwj;</div>
                    <Nav.Link href="/" onClick={(e) => { e.preventDefault(); setLanguage('en'); }} className={`language-selector ${language === 'en' ? 'font-weight-bold' : ''}`}>en</Nav.Link>
                    {isLoggedIn ? (
                        <Button onClick={handleLogout} variant="danger" className="mx-3">
                            Logout
                        </Button>
                    ) : null}
                </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
    );
}

export default NavigationBar;
