import React from 'react';
import NavigationBar from '../components/Navbar';
import Footer from '../components/Footer';
import { LanguageContext } from '../contexts/LanguageContext';
import ControllingText from '../components/ControllingText';
import B4 from '../images/B4.webp';
import B5 from '../images/B5-p-1600.webp';
import { Helmet } from 'react-helmet';
import EditableText from '../components/EditableText';

const ControllingPage: React.FC = () => {

  const { language } = React.useContext(LanguageContext);
  return (
    <div className="d-flex flex-column min-vh-100">
      {language === 'de' ?
      <Helmet>
        <title>Controlling - meltshop365</title>
        <meta name="description" content="Die meltshop|365 Einsatzberechnung ermittelt die kostenoptimale Zusammenstellung der Einsatzstoffe einer Charge basierend auf der Zielanalyse nach dem Einschmelzen und den tatsächlichen Beständen der Rohstoffe." />
        <meta name="keywords" content="ERP, Industrie 4.0, Schmelz- und Gießbetrieb, Software, ERP-Software, Cloud" />
      </Helmet>
      :
      <Helmet>
        <title>Controlling - meltshop365</title>
        <meta name="description" content="The meltshop|365 standard edition offers heat reports, shift reports (daily, weekly or monthly) and consumption reports for variable periods. Depending on intended purposes, the level of detail of the charge logs can be adjusted. As an example, it can be defined, whether the prices of charge materials should be displayed, or trends are included." />
        <meta name="keywords" content="ERP, Industrie 4.0, Schmelz- und Gießbetrieb, Software, ERP-Software, Cloud" />
      </Helmet>
      }
    <NavigationBar />
    <div className="bg-image d-flex flex-column justify-content-center align-items-center flex-grow-1">
      <div className="bg-white p-5 rounded mx-5 my-5 w-75">
      {language === 'de' ? <h1 className="my-4 mt-5 h1-grips" id='227'><EditableText id='227'  isH1/></h1> : <h1 className="my-4 mt-5 h1-grips" id='228'><EditableText id='228'  isH1/></h1>}
          {language === 'de' ? <h4 className='h4-grips' id='229'><EditableText id='229'  /></h4> : <h4 className='h4-grips' id='230'><EditableText id='230'  /></h4>}
        <div className="my-4" style={{width: '100px', borderBottom: '10px solid red'}}></div>
        <ControllingText />
        <div className='d-flex justify-content-center'>
        <img src={B4} alt="Controlling" className={`img-fluid`}/>
        </div>
        <div className='d-flex justify-content-center'>
        <img src={B5} alt="Controlling" className={`img-fluid`}/>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
}

export default ControllingPage;