import React from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import '../css/Home.css';
import { useNavigate } from 'react-router-dom';
import EditableText from './EditableText';

const HomeSection2: React.FC = () => {

    const { language } = React.useContext(LanguageContext);
    const navigate = useNavigate();
    return (
        <div>
            {language === 'de' ?
               <>
               <div className="container-fluid">
               <div className="row">
            <div className="col-md-6 d-flex justify-content-center align-items-center p-0" style={{backgroundColor: '#144378'}}>
                <div className='section2-padding'>
                    <h3 className='text-white' id='15'><EditableText id='15'  /></h3>
                    <div className="my-4" style={{width: '80px', borderBottom: '10px solid white', zIndex: 5}}></div>
                    <div className='text-white fs-5 fs-md-3 fs-lg-2' id='17'><EditableText id='17'  /></div>
          <button type="button" className="btn btn-outline-light btn-lg mt-4" onClick={() => navigate("/merkmale")}>Erfahren Sie mehr</button>
                </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center p-0" style={{backgroundColor: '#1960ab'}}>
                <div className='section2-padding'>
                    <h3 className='text-white' id='19'><EditableText id='19'  /></h3>
                    <div className="my-4" style={{width: '80px', borderBottom: '10px solid white', zIndex: 5}}></div>
                    <ul className='white-ul'>
                        <li>
                            <div className='text-white fs-4 fs-md-3 fs-lg-2' id='21'>
                            <EditableText id='21'  />
                            </div>
                        </li>
                        <li>
                            <div className='text-white fs-4 fs-md-3 fs-lg-2' id='23'>
                            <EditableText id='23'  />
                            </div>
                        </li>
                        <li>
                            <div className='text-white fs-4 fs-md-3 fs-lg-2' id='25'>
                            <EditableText id='25'  />
                            </div>
                        </li>
                        <li>
                            <div className='text-white fs-4 fs-md-3 fs-lg-2' id='27'>
                            <EditableText id='27'  />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        </div>
            </>
                :
                <>
                <div className="container-fluid">
                <div className="row">
            <div className="col-md-6 d-flex justify-content-center align-items-center p-0" style={{backgroundColor: '#144378'}}>
                <div className='section2-padding'>
                    <h3 className='text-white' id='16'><EditableText id='16'  /></h3>
                    <div className="my-4" style={{width: '80px', borderBottom: '10px solid white', zIndex: 5}}></div>
                    <div className='text-white fs-5 fs-md-3 fs-lg-2' id='18'><EditableText id='18'  /></div>
          <button type="button" className="btn btn-outline-light btn-lg mt-4" onClick={() => navigate("/merkmale")}>Erfahren Sie mehr</button>
                </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center p-0" style={{backgroundColor: '#1960ab'}}>
                <div className='section2-padding'>
                    <h3 className='text-white' id='20'><EditableText id='20'  /></h3>
                    <div className="my-4" style={{width: '80px', borderBottom: '10px solid white', zIndex: 5}}></div>
                    <ul className='white-ul'>
                        <li>
                            <div className='text-white fs-4 fs-md-3 fs-lg-2' id='22'>
                            <EditableText id='22'  />
                            </div>
                        </li>
                        <li>
                            <div className='text-white fs-4 fs-md-3 fs-lg-2' id='24'>
                            <EditableText id='24'  />
                            </div>
                        </li>
                        <li>
                            <div className='text-white fs-4 fs-md-3 fs-lg-2' id='26'>
                            <EditableText id='26'  />
                            </div>
                        </li>
                        <li>
                            <div className='text-white fs-4 fs-md-3 fs-lg-2' id='28'>
                            <EditableText id='28'  />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        </div>
            </>
            }
        </div>
    );
}

export default HomeSection2;