import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/LoginContext';

function Login() {
    const { isLoggedIn, setIsLoggedIn } = React.useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
  
    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            const response = await axios.post('https://meltshop365.com/api/login', { 'username':username, 'password':password });
            localStorage.setItem('token', response.data.token);
            setIsLoggedIn(true);
            navigate('/');
        } catch (error) {
            console.error('Failed to login', error);
            setPassword('');
            setUsername('');
            setError(error as React.SetStateAction<null>);
        }
    };

  return (
    <Container fluid className="grips-bg d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
      <Row className='w-75 align-items-center justify-content-center'>
        <Col xs={12} md={6} lg={4}>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label className='text-white'>Username</Form.Label>
              <Form.Control size="lg" type="text" placeholder="Enter username" value={username} onChange={e => setUsername(e.target.value)} />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label className='text-white'>Password</Form.Label>
              <Form.Control size="lg" type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
            </Form.Group>
            <br />
            <Button variant="primary" type="submit">
              Login
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;