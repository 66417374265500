import React from 'react';
import NavigationBar from '../components/Navbar';
import Footer from '../components/Footer';
import { LanguageContext } from '../contexts/LanguageContext';
import SchmelzplanungText from '../components/SchmelzplanungText';
import B1 from '../images/B1.webp';
import { Helmet } from 'react-helmet';
import EditableText from '../components/EditableText';

const SchmelzplanungPage: React.FC = () => {

  const { language } = React.useContext(LanguageContext);
  return (
    <div className="d-flex flex-column min-vh-100">
      {language === 'de' ?
      <Helmet>
        <title>Schmelzplanung - meltshop365</title>
        <meta name="description" content="Die Reihenfolgeplanung von Schmelzaufträgen im Schmelzbetrieb erfolgt unter Berücksichtigung der Kapazität der Schmelzanlagen, der Belegung nachgeschalteter sekundärmetallurgischer Anlagen, der Gießkapazitäten als auch weiterer Restriktionen wie z. B. den Legierungsanteilen der Werkstoffe oder Wärmebehandlungskapazitäten. meltshop|365 bietet ein universelles Werkzeug zur Schmelzplanung für variable Zeiträume." />
        <meta name="keywords" content="ERP, Industrie 4.0, Schmelz- und Gießbetrieb, Software, ERP-Software, Cloud" />
      </Helmet>
      :
      <Helmet>
        <title>Heat Planning - meltshop365</title>
        <meta name="description" content="Considering melting units, allocation of downstream secondary metallurgical units, casting capacities or other restrictions (e.g. alloy content or melting capacities), the sequential arrangement of melting orders is set up. meltshop|365 is a universal tool for melt planning in variable periods." />
        <meta name="keywords" content="ERP, Industry 4.0, Melting and Casting, Software, ERP Software, Cloud" />
      </Helmet>
      }
    <NavigationBar />
    <div className="bg-image d-flex flex-column justify-content-center align-items-center flex-grow-1">
      <div className="bg-white p-5 rounded mx-5 my-5 w-75">
      {language === 'de' ?<h1 className="my-4 mt-5 h1-grips" id='163'><EditableText id='163'  isH1/></h1> : <h1 className="my-4 mt-5 h1-grips" id='164'><EditableText id='164'  isH1/></h1>}
      {language === 'de' ?<h4 className='h4-grips' id='165'><EditableText id='165'  /></h4> : <h4 className='h4-grips' id='166'><EditableText id='166'  /></h4>}
        <div className="my-4" style={{width: '100px', borderBottom: '10px solid red'}}></div>
        <SchmelzplanungText />
        <div className='d-flex justify-content-center'>
        <img src={B1} alt="Schmelzplanung" className='img-fluid'/>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
}

export default SchmelzplanungPage;