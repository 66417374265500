import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../images/grips-logo.svg';
import { LanguageContext } from '../contexts/LanguageContext';

const Footer = () => {
    const { language } = React.useContext(LanguageContext);
    return (
        <div className='bg-body-tertiary footer-mt'>
            <Container fluid className="grips-bg text-white text-center py-3">
                <Row className='justify-content-center'>
                    <Col className='col-4'>
                        <img src={logo} alt="Logo" className="d-inline-block align-top img-fluid w-25" />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <a href="/kontakt" className="text-white mx-2 fs-6 text-decoration-none">
                            {language === 'de' ? 'KONTAKT' : 'CONTACT'}
                        </a>
                        <a href="/impressum" className="text-white mx-2 fs-6 text-decoration-none">
                            {language === 'de' ? 'IMPRESSUM' : 'IMPRINT'}
                        </a>
                        <a href="/datenschutz" className="text-white mx-2 fs-6 text-decoration-none">
                            {language === 'de' ? 'DATENSCHUTZ' : 'DATA PROTECTION'}
                        </a>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="bg-white text-black text-center py-3">
                <Row>
                    <Col>
                        © 2020- meltshop365 by GRIPS Industrial IT Solutions GmbH
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Footer;