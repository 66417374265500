import React from 'react';
import NavigationBar from '../components/Navbar';
import Footer from '../components/Footer';
import { LanguageContext } from '../contexts/LanguageContext';
import RohstoffoptimierungText from '../components/RohstoffoptimierungText';
import RAW from '../images/Rohstoffoptimierung.webp';
import RAWEN from '../images/Raw-material-optimization.webp';
import { Helmet } from 'react-helmet';
import EditableText from '../components/EditableText';

const RohstoffoptimierungPage: React.FC = () => {

  const { language } = React.useContext(LanguageContext);
  return (
    <div className="d-flex flex-column min-vh-100">
      {language === 'de' ?
      <Helmet>
        <title>Rohstoffoptimierung - meltshop365</title>
        <meta name="description" content="Der Anteil der Rohstoffkosten (Schrotte, Legierungsstoffe sowie Metalle) an den gesamten Produktionskosten beträgt bei Schmelzbetrieben je nach Legierungsanteil bis zu 60 %." />
        <meta name="keywords" content="ERP, Industrie 4.0, Schmelz- und Gießbetrieb, Software, ERP-Software, Cloud" />
      </Helmet>
      :
      <Helmet>
        <title>Raw Material Optimization - meltshop365</title>
        <meta name="description" content="Market prices and availabilities of raw material are permanently exposed to inconsistent fluctuations. The conventional approach to order raw material based on lists or recipes, brings the difficulty of lead times, which are far too long. This misses the target of a versatile, resource- and energy-efficient production." />
        <meta name="keywords" content="ERP, Industry 4.0, Melting and Casting Operation, Software, ERP Software, Cloud" />
      </Helmet>
      }
    <NavigationBar />
    <div className="bg-image d-flex flex-column justify-content-center align-items-center flex-grow-1">
      <div className="bg-white p-5 rounded mx-5 my-5 w-75">
      {language === 'de' ?<h1 className="my-4 mt-5 h1-grips" id='139'><EditableText id='139'  isH1/></h1> : <h1 className="my-4 mt-5 h1-grips" id='140'><EditableText id='140'  isH1/></h1>}
      {language === 'de' ?<h4 className='h4-grips' id='141'><EditableText id='141'  /></h4> : <h4 className='h4-grips' id='142'><EditableText id='142'  /></h4>}
        <div className="my-4" style={{width: '100px', borderBottom: '10px solid red'}}></div>
        <RohstoffoptimierungText />
        <div className={`d-flex justify-content-center`}>
        <img src={language === 'de' ? RAW : RAWEN} alt="Materialwirtschaft" className='img-fluid'/>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
}

export default RohstoffoptimierungPage;