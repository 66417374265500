import React from 'react';
import NavigationBar from '../components/Navbar';
import Footer from '../components/Footer';
import { LanguageContext } from '../contexts/LanguageContext';
import ProcessvarsText from '../components/ProcessvarsText';
import processvarsEN from '../images/EN ProcessVars.webp';
import processvarsDE from '../images/DE ProcessVars.webp';
import { Helmet } from 'react-helmet';
import EditableText from '../components/EditableText';

const ProcessvarsPage: React.FC = () => {

  const { language } = React.useContext(LanguageContext);
  return (
    <div className="d-flex flex-column min-vh-100">
      {language === 'de' ?
      <Helmet>
        <title>Processvars - meltshop365</title>
        <meta name="description" content="ProcessVars ist eine Suite von Diensten, Bibliotheken und Werkzeugen zur Erstellung von leistungsfähigen Anwendungen für die Industrie 4.0 Prozessautomatisierung" />
        <meta name="keywords" content="ERP, Industrie 4.0, Schmelz- und Gießbetrieb, Software, ERP-Software, Cloud" />
      </Helmet>
      :
      <Helmet>
        <title>Processvars - meltshop365</title>
        <meta name="description" content="Adaptable, resource-efficient and ergonomically-producing smart factories provide all relevant information in real-time by connecting entities that are involved in the value adding chain. Dynamic, real-time optimized and self-organizing networksin adding value are created thanks to the ability of calculating costs and deriving quality and production optimizations atany time." />
        <meta name="keywords" content="ERP, Industrie 4.0, Schmelz- und Gießbetrieb, Software, ERP-Software, Cloud" />
      </Helmet>
    }
    <NavigationBar />
    <div className="bg-image d-flex flex-column justify-content-center align-items-center flex-grow-1">
      <div className="bg-white p-5 rounded mx-5 my-5 w-75">
      {language === 'de' ?<h1 className="my-4 mt-5 h1-grips" id='265'><EditableText id='265'  isH1/></h1> : <h1 className="my-4 mt-5 h1-grips" id='266'><EditableText id='266'  isH1/></h1>}
          {language === 'de' ?<h4 className='h4-grips' id='267'><EditableText id='267'  /></h4> : <h4 className='h4-grips' id='268'><EditableText id='268'  /></h4>}
        <div className="my-4" style={{width: '100px', borderBottom: '10px solid red'}}></div>
        <ProcessvarsText />
        <div className='d-flex justify-content-center'>
        <img src={language === 'de' ? processvarsDE : processvarsEN} alt="EINSATZBERECHNUNG" className='img-fluid'/>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
}

export default ProcessvarsPage;