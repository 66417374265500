import React from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import { useNavigate } from 'react-router-dom';
import EditableText from './EditableText';

const AuftragsabrechnungText: React.FC = () => {

    const { language } = React.useContext(LanguageContext);
    const navigate = useNavigate();
    return (
        <div>
            {language === 'en' ?
               <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='243'>
               <EditableText id='243'  />
               <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='244'><EditableText id='244'  /></div>
            <br></br>
            <br></br>
            <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => navigate("/merkmale")}>Back to Overview</button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            </>
                :
                <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='245'>
               <EditableText id='245'  />
              <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='246'><EditableText id='246'  /></div>
            <br></br>
            <br></br>
            <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => navigate("/merkmale")}>Zurück zur Übersicht</button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            </>
            }
        </div>
    );
}

export default AuftragsabrechnungText;