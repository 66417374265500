import React, { useState, useEffect, ChangeEvent, FC, useContext } from "react";
import DOMPurify from "dompurify";
import { AuthContext } from "../contexts/LoginContext";
import axios from "axios";

interface EditableTextProps {
  id: string;
  isH1?: boolean;
  isH2?: boolean;
  isTL?: boolean;
}

const EditableText: FC<EditableTextProps> = ({ id, isH1 = false, isTL = false, isH2 = false }) => {
  const [text, setText] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const { isLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    axios.get(`https://meltshop365.com/getText/${id}`)
      .then(response => setText(response.data.text.text))
      .catch(error => console.error(error));
  }, [id]);

  const handleDoubleClick = () => {
    if (isLoggedIn) { 
      setIsEditing(true);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
  
    const token = localStorage.getItem('token'); // Get the token from localStorage
  
    // Make a PATCH request to update the text
    axios.patch(`https://meltshop365.com/updateText/${id}`, { text }, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token || '',
      },
    })
    .then(response => console.log(response.data))
    .catch(error => console.error(error));
  };

  const sanitizedHTML = DOMPurify.sanitize(text);

  return (
    <div onDoubleClick={handleDoubleClick}>
      {isEditing ? (
        <textarea
          id={id}
          value={text}
          onChange={handleChange}
          onBlur={handleBlur}
          className="form-control form-control-lg"
        />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} className={isTL ? "fs-6" : isH1 ? "h1-responsive" : isH2 ? "h2-responsive" : "text-responsive"}/>
      )}
    </div>
  );
};

export default EditableText;