import React from 'react';
import NavigationBar from '../components/Navbar';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../contexts/LanguageContext';
import '../css/Home.css';
import icons from '../images/icons-home-grips-neu.svg';
import HomeSection1 from '../components/HomeSection1';
import HomeSection2 from '../components/HomeSection2';
import HomeSection3 from '../components/HomeSection3';
import EditableText from '../components/EditableText';
import { Helmet } from 'react-helmet';


const HomePage: React.FC = () => {

  const { language } = React.useContext(LanguageContext);
  const navigate = useNavigate();
  return (
    <div>
      {language === 'de' ?
      <Helmet>
        <title>Home - meltshop365 by GRIPS Industrial IT Solutions</title>
        <meta name="description" content="Die einzigartige und umfassende Lösung für Ihren gesamten Schmelzbetrieb nach den Leitlinien von Industrie 4.0" />
        <meta name="keywords" content="ERP, Industrie 4.0, Schmelz- und Gießbetrieb, Software, ERP-Software, Cloud" />
      </Helmet>
      :
      <Helmet>
        <title>Home - meltshop365 by GRIPS Industrial IT Solutions</title>
        <meta name="description" content="meltshop|365 integrates existing and new metallurgical processing units of your melting and casting shop into a Smart Factory according to the guidelines of Industry 4.0" />
        <meta name="keywords" content="ERP, Industry 4.0, melting and casting shop, software, ERP software, cloud" />
      </Helmet>
      }
      <NavigationBar />
      <div className="custom-height bg-image">
        <div className="row h-100 m-0">
          <div className="col-lg-8 col-12 p-0">
            <div className="rectangle d-flex justify-content-center align-items-center">
            <div className="div-block-4 mx-5 p-5" style={{zIndex: 100}}>
                  <div className="my-4" style={{width: '100px', borderBottom: '10px solid red', zIndex: 5}}></div>
                  {language === 'de' ? <h1 className='text-white fs-1 fs-md-3 fs-lg-1' id='1' style={{zIndex: 100}}><EditableText id='1' isH1/></h1> : <h1 className='text-white fs-1 fs-md-3 fs-lg-1' id='2'><EditableText id='2' isH1/></h1>}
                    <br></br>
                    <br></br>
                    <button type="button" className="btn btn-outline-light btn-lg" onClick={() => navigate("/merkmale")}>{language === 'de' ? 'Erfahren Sie mehr' : 'Learn more'}</button>
                  </div>
            </div>
          </div>
          <div className="image-container">
            <div className='d-flex w-100'>
              <img src={icons} alt="grips icons" className='img-fluid image-2 image-2-1'/>
            </div>
          </div>
        </div>
      </div>
      <HomeSection1 />
      <HomeSection2 />
      <HomeSection3 />
      <Footer />
    </div>
  );
}

export default HomePage;

