import React from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import { useNavigate } from 'react-router-dom';
import EditableText from './EditableText';

const MaterialwirtschaftText: React.FC = () => {

    const { language } = React.useContext(LanguageContext);
    const navigate = useNavigate();
    return (
        <div>
            {language === 'en' ?
               <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='157'>
               <EditableText id='157'  />
               <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='158'><EditableText id='158'  /></div>
            <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='159'><EditableText id='159'  /></div>
            <br></br>
            <br></br>
            <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => navigate("/merkmale")}>Back to Overview</button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            </>
                :
                <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='160'>
               <EditableText id='160'  />
               <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='161'><EditableText id='161'  /></div>
            <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='162'><EditableText id='162'  /></div>
            <br></br>
            <br></br>
            <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => navigate("/merkmale")}>Zurück zur Übersicht</button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            </>
            }
        </div>
    );
}

export default MaterialwirtschaftText;