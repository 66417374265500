import React from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import { useNavigate } from 'react-router-dom';
import reg from '../images/regestrieren-grips.svg';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import EditableText from './EditableText';


const HomeSection3: React.FC = () => {

    const { language } = React.useContext(LanguageContext);
    const days = language === 'de' 
    ? ['SONNTAG', 'MONTAG', 'DIENSTAG', 'MITTWOCH', 'DONNERSTAG', 'FREITAG', 'SAMSTAG'] 
    : ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    const date = new Date();
    const dayName = days[date.getDay()];
    const dayOfMonth = date.getDate();
    const locale = language === 'de' ? 'de-DE' : 'en-US';
    const monthName = date.toLocaleString(locale, { month: 'long' });
    const currentYear = date.getFullYear();
    const monthNameUpper = monthName.toUpperCase();
    const formattedDate = `${dayName}, ${dayOfMonth.toString().padStart(2, '0')}. ${monthNameUpper} ${currentYear}`;
    const date2 = new Date();
    date2.setDate(date2.getDate() + 4);
    const dayName2 = days[date2.getDay()];
    const dayOfMonth2 = date2.getDate();
    const monthName2 = date2.toLocaleString(locale, { month: 'long' });
    const currentYear2 = date2.getFullYear();
    const monthNameUpper2 = monthName2.toUpperCase();
    const formattedDate2 = `${dayName2}, ${dayOfMonth2.toString().padStart(2, '0')}. ${monthNameUpper2} ${currentYear2}`;
    const date3 = new Date();
    date3.setDate(date3.getDate() + 6);
    const dayName3 = days[date3.getDay()];
    const dayOfMonth3 = date3.getDate();
    const monthName3 = date3.toLocaleString(locale, { month: 'long' });
    const currentYear3 = date3.getFullYear();
    const monthNameUpper3 = monthName3.toUpperCase();
    const formattedDate3 = `${dayName3}, ${dayOfMonth3.toString().padStart(2, '0')}. ${monthNameUpper3} ${currentYear3}`;
    const date4 = new Date();
    date4.setDate(date4.getDate() + 10);
    const dayName4 = days[date4.getDay()];
    const dayOfMonth4 = date4.getDate();
    const monthName4 = date4.toLocaleString(locale, { month: 'long' });
    const currentYear4 = date4.getFullYear();
    const monthNameUpper4 = monthName4.toUpperCase();
    const formattedDate4 = `${dayName4}, ${dayOfMonth4.toString().padStart(2, '0')}. ${monthNameUpper4} ${currentYear4}`;
    const date5 = new Date();
    date5.setDate(date5.getDate() + 34);
    const dayName5 = days[date5.getDay()];
    const dayOfMonth5 = date5.getDate();
    const monthName5 = date5.toLocaleString(locale, { month: 'long' });
    const currentYear5 = date5.getFullYear();
    const monthNameUpper5 = monthName5.toUpperCase();
    const formattedDate5 = `${dayName5}, ${dayOfMonth5.toString().padStart(2, '0')}. ${monthNameUpper5} ${currentYear5}`;

    const navigate = useNavigate();
    return (
        <div className='my-5'>
            {language === 'de' ?
                <>
                    <div>
                        <VerticalTimeline lineColor='#144378'>
                            <VerticalTimelineElement
                                date={formattedDate}
                                iconStyle={{ background: '#144378', color: '#144378' }}
                                icon={<img src={reg} alt="Event 1" />}
                            >
                                <h4 className='h4-timeline' id='29'><EditableText id='29'  /></h4>
                                <div className='text-grips fs-6 fs-md-5 fs-lg-5' id='30'><EditableText id='30'  isTL/></div>
                                <ul className="text-grips fs-6 fs-md-5 fs-lg-5">
                                    <li>
                                    <div className="text-grips fs-6 fs-md-5 fs-lg-5" id='31'><EditableText id='31'  isTL/></div>
                                    </li>
                                    <li>
                                    <div className="text-grips fs-6 fs-md-5 fs-lg-5" id='32'><EditableText id='32'  isTL/></div>
                                    </li>
                                    <li>
                                    <div className="text-grips fs-6 fs-md-5 fs-lg-5" id='33'><EditableText id='33'  isTL/></div>
                                    </li>
                                    <li>
                                    <div className="text-grips fs-6 fs-md-5 fs-lg-5" id='34'><EditableText id='34'  isTL/></div>
                                    </li>
                                </ul>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date={formattedDate2}
                                iconStyle={{ background: '#144378', color: '#144378' }}
                                icon={<img src={reg} alt="Event 2" />}
                            >
                                <h4 className='h4-timeline' id='35'><EditableText id='35'  /></h4>
                                <div className='text-grips fs-6 fs-md-5 fs-lg-5' id='36'><EditableText id='36'  isTL/></div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date={formattedDate3}
                                iconStyle={{ background: '#144378', color: '#144378' }}
                                icon={<img src={reg} alt="Event 2" />}
                            >
                                <h4 className='h4-timeline' id='37'><EditableText id='37'  /></h4>
                                <div className='text-grips fs-6 fs-md-5 fs-lg-5' id='38'><EditableText id='38'  isTL/></div>
                                <ul>
                                <li>
                                <div className='text-grips fs-6 fs-md-5 fs-lg-5' id='39'><EditableText id='39'  isTL/></div>
                                </li>
                                <li>
                                <div className='text-grips fs-6 fs-md-5 fs-lg-5' id='40'><EditableText id='40'  isTL/></div>
                                </li>
                                <li>
                                <div className='text-grips fs-6 fs-md-5 fs-lg-5' id='41'><EditableText id='41'  isTL/></div>
                                </li>
                                <li>
                                <div className='text-grips fs-6 fs-md-5 fs-lg-5' id='42'><EditableText id='42'  isTL/></div>
                                </li>
                                <li>
                                <div className='text-grips fs-6 fs-md-5 fs-lg-5' id='43'><EditableText id='43'  isTL/></div>
                                </li>
                            </ul>
                            <div className='text-grips fs-6 fs-md-5 fs-lg-5' id='44'><EditableText id='44'  isTL/></div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date={formattedDate4}
                                iconStyle={{ background: '#144378', color: '#144378' }}
                                icon={<img src={reg} alt="Event 2" />}
                            >
                                <h4 className='h4-timeline' id='45'><EditableText id='45'  /></h4>
                                <div className='text-grips fs-6 fs-md-5 fs-lg-5' id='46'><EditableText id='46'  isTL/></div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date={formattedDate5}
                                iconStyle={{ background: '#144378', color: '#144378' }}
                                icon={<img src={reg} alt="Event 2" />}
                            >
                                <h4 className='h4-timeline' id='47'><EditableText id='47'  /></h4>
                                <div className='text-grips fs-6 fs-md-5 fs-lg-5' id='48'><EditableText id='48'  isTL/></div>
                            </VerticalTimelineElement>
                        </VerticalTimeline>
                        <br />
                        <br />
                        <button type="button" className="btn btn-outline-danger btn-lg mx-auto d-block" onClick={() => navigate("/merkmale")}>Erfahren Sie mehr</button>
                    </div>
                </>

                    :
                    <>
<div>
                        <VerticalTimeline lineColor='#144378'>
                            <VerticalTimelineElement
                                date={formattedDate}
                                iconStyle={{ background: '#144378', color: '#144378' }}
                                icon={<img src={reg} alt="Event 1" />}
                            >
                                <h4 className='h4-timeline' id='49'><EditableText id='49'  /></h4>
                                <div className='text-grips fs-6 fs-md-5 fs-lg-5' id='50'><EditableText id='50'  isTL/></div>
                                <ul className="text-grips fs-6 fs-md-5 fs-lg-5">
                                    <li>
                                    <div className="text-grips fs-6 fs-md-5 fs-lg-5" id='51'><EditableText id='51'  isTL/></div>
                                    </li>
                                    <li>
                                    <div className="text-grips fs-6 fs-md-5 fs-lg-5" id='52'><EditableText id='52'  isTL/></div>
                                    </li>
                                    <li>
                                    <div className="text-grips fs-6 fs-md-5 fs-lg-5" id='53'><EditableText id='53'  isTL/></div>
                                    </li>
                                    <li>
                                    <div className="text-grips fs-6 fs-md-5 fs-lg-5" id='54'><EditableText id='54'  isTL/></div>
                                    </li>
                                </ul>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date={formattedDate2}
                                iconStyle={{ background: '#144378', color: '#144378' }}
                                icon={<img src={reg} alt="Event 2" />}
                            >
                                <h4 className='h4-timeline' id='55'><EditableText id='55'  /></h4>
                                <div className='text-grips fs-6 fs-md-5 fs-lg-5' id='56'><EditableText id='56'  isTL/></div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date={formattedDate3}
                                iconStyle={{ background: '#144378', color: '#144378' }}
                                icon={<img src={reg} alt="Event 2" />}
                            >
                                <h4 className='h4-timeline' id='57'><EditableText id='57'  /></h4>
                                <div className='text-grips fs-6 fs-md-5 fs-lg-5' id='58'><EditableText id='58'  isTL/></div>
                                <ul>
                                <li>
                                <div className='text-grips fs-6 fs-md-5 fs-lg-5' id='59'><EditableText id='59'  isTL/></div>
                                </li>
                                <li>
                                <div className='text-grips fs-6 fs-md-5 fs-lg-5' id='60'><EditableText id='60'  isTL/></div>
                                </li>
                                <li>
                                <div className='text-grips fs-6 fs-md-5 fs-lg-5' id='61'><EditableText id='61'  isTL/></div>
                                </li>
                                <li>
                                <div className='text-grips fs-6 fs-md-5 fs-lg-5' id='62'><EditableText id='62'  isTL/></div>
                                </li>
                                <li>
                                <div className='text-grips fs-6 fs-md-5 fs-lg-5' id='63'><EditableText id='63'  isTL/></div>
                                </li>
                            </ul>
                            <div className='text-grips fs-6 fs-md-5 fs-lg-5' id='64'><EditableText id='64'  isTL/></div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date={formattedDate4}
                                iconStyle={{ background: '#144378', color: '#144378' }}
                                icon={<img src={reg} alt="Event 2" />}
                            >
                                <h4 className='h4-timeline' id='65'><EditableText id='65'  /></h4>
                                <div className='text-grips fs-6 fs-md-5 fs-lg-5' id='66'><EditableText id='66'  isTL/></div>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                date={formattedDate5}
                                iconStyle={{ background: '#144378', color: '#144378' }}
                                icon={<img src={reg} alt="Event 2" />}
                            >
                                <h4 className='h4-timeline' id='67'><EditableText id='67'  /></h4>
                                <div className='text-grips fs-6 fs-md-5 fs-lg-5' id='68'><EditableText id='68'  isTL/></div>
                            </VerticalTimelineElement>
                        </VerticalTimeline>
                        <br />
                        <br />
                        <button type="button" className="btn btn-outline-danger btn-lg mx-auto d-block" onClick={() => navigate("/merkmale")}>Learn more</button>
                    </div>
                    </>
            }
                </div>
    );
}

            export default HomeSection3;