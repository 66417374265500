import React from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import EditableText from './EditableText';

const KontaktText: React.FC = () => {

  const { language } = React.useContext(LanguageContext);
  return (
      <div>
        {language === 'de' ?
          <div className="text-white fs-5" id='307'>
              <EditableText id='307'  />
          </div>
          :
          <div className="text-white fs-5" id='308'>
              <EditableText id='308'  />
          </div>
          }
      </div>
  );
}

export default KontaktText;