import React from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import EditableText from './EditableText';

const LizenzierungText: React.FC = () => {

    const { language } = React.useContext(LanguageContext);
    return (
        <div>
            {language === 'en' ?
                <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='291'>
                    <EditableText id='291'  />
                    <br></br>
                    <br></br>
                </div><h2 className='h2-grips' id='292'><EditableText id='292'  isH2/></h2>
                <br />
                <ul>
                        <li>
                            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='293'><EditableText id='293'  /></div>
                        </li>
                        <li>
                            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='294'><EditableText id='294'  /></div>
                        </li>
                        <li>
                            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='295'><EditableText id='295'  /></div>
                        </li>
                        <li>
                            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='296'><EditableText id='296'  /></div>
                        </li>
                        <li>
                            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='297'><EditableText id='297'  /></div>
                        </li>
                    </ul></>
                :
                <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='298'>
                    <EditableText id='298'  />
                    <br></br>
                    <br></br>
                </div><h2 className='h2-grips' id='299'><EditableText id='299'  isH2/></h2>
                <br />
                <ul>
                        <li>
                            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='300'><EditableText id='300'  /></div>
                        </li>
                        <li>
                            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='301'><EditableText id='301'  /></div>
                        </li>
                        <li>
                            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='302'><EditableText id='302'  /></div>
                        </li>
                        <li>
                            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='303'><EditableText id='303'  /></div>
                        </li>
                        <li>
                            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='304'><EditableText id='304'  /></div>
                        </li>
                    </ul></>
            }
        </div>
    );
}

export default LizenzierungText;