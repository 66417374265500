import React from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import { useNavigate } from 'react-router-dom';
import EditableText from './EditableText';

const EinsatzberechnungText: React.FC = () => {

    const { language } = React.useContext(LanguageContext);
    const navigate = useNavigate();
    return (
        <div>
            {language === 'en' ?
               <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='181'>
               <EditableText id='181'  />
                <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='182'><EditableText id='182'  /></div>
            <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='183'><EditableText id='183'  /></div>
            <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='184'><EditableText id='184'  /></div>
            <br />
           <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='185'><EditableText id='185'  /></div>
            <br></br>
            <br></br>
            <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => navigate("/merkmale")}>Back to Overview</button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            </>
                :
                <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='186'>
               <EditableText id='186'  />
                <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='187'>
            <EditableText id='187'  /></div>
            <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='188'>
            <EditableText id='188'  /></div>
            <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='189'>
            <EditableText id='189'  /></div>
            <br />
           <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='190'>
           <EditableText id='190'  /></div>
           <br />
            <br></br>
            <br></br>
            <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => navigate("/merkmale")}>Zurück zur Übersicht</button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            </>
            }
        </div>
    );
}

export default EinsatzberechnungText;