import React from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import EditableText from './EditableText';

const ImpressumText: React.FC = () => {

    const { language } = React.useContext(LanguageContext);
    return (
        <div>
            {language === 'de' ?
                <div className='text-white' id='311'>
                    <EditableText id='311'  />
                </div>
                :
                <div className='text-white' id='312'>
                    <EditableText id='312'  />
                </div>
            }
        </div>
    );
}

export default ImpressumText;