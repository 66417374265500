import React from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import '../css/Home.css';
import app from '../images/meltshop-img-app.webp';
import EditableText from './EditableText';

const HomeSection1: React.FC = () => {

    const { language } = React.useContext(LanguageContext);
    return (
        <div>
            {language === 'de' ?
               <>
               <div className='d-flex justify-content-center align-items-center mx-5 mw-100'>
                <div className='w-100' style={{zIndex:100}}>
                    <h2 className='h1-grips w-50 text-start mt-5 pt-5 grips-margin' id='3'>
                    <EditableText id='3'  isH2/>
                    </h2>
                    <div className="my-4 grips-margin" style={{width: '100px', borderBottom: '10px solid red'}}></div>
                    <ul className='grips-margin'>
                    <li>
                       <div className='text-grips fs-5 fs-md-3 fs-lg-2' id='5'><EditableText id='5'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-5 fs-md-3 fs-lg-2' id='7'><EditableText id='7'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-5 fs-md-3 fs-lg-2' id='9'><EditableText id='9'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-5 fs-md-3 fs-lg-2' id='11'><EditableText id='11'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-5 fs-md-3 fs-lg-2' id='13'><EditableText id='13'  /></div>
                   </li>
                    </ul>
                </div>
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <div className='d-flex justify-content-center align-items-center mx-5 mw-100 pb-5 mb-5'>
                <img src={app} alt="grips icons" className='img-fluid mx-auto'/>
                </div>
            </>
                :
                <>
               <div className='d-flex justify-content-center align-items-center mx-5 mw-100'>
                <div className='w-100' style={{zIndex:100}}>
                    <h2 className='h1-grips w-50 text-start mt-5 pt-5 grips-margin' id='4'>
                    <EditableText id='4'  isH2/>
                    </h2>
                    <div className="my-4 grips-margin" style={{width: '100px', borderBottom: '10px solid red'}}></div>
                    <ul className='grips-margin'>
                    <li>
                       <div className='text-grips fs-5 fs-md-3 fs-lg-2' id='6'><EditableText id='6'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-5 fs-md-3 fs-lg-2' id='8'><EditableText id='8'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-5 fs-md-3 fs-lg-2' id='10'><EditableText id='10'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-5 fs-md-3 fs-lg-2' id='12'><EditableText id='12'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-5 fs-md-3 fs-lg-2' id='14'><EditableText id='14'  /></div>
                   </li>
                    </ul>
                </div>
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <div className='d-flex justify-content-center align-items-center mx-5 mw-100 pb-5 mb-5'>
                <img src={app} alt="grips icons" className='img-fluid mx-auto'/>
                </div>
            </>
            }
        </div>
    );
}

export default HomeSection1;