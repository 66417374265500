import React from 'react';
import NavigationBar from '../components/Navbar';
import Footer from '../components/Footer';
import { LanguageContext } from '../contexts/LanguageContext';
import EinsatzberechnungText from '../components/EinsatzberechnungText';
import B2 from '../images/B2.webp';
import { Helmet } from 'react-helmet';
import EditableText from '../components/EditableText';

const EinsatzberechnungPage: React.FC = () => {

  const { language } = React.useContext(LanguageContext);
  return (
    <div className="d-flex flex-column min-vh-100">
      {language === 'de' ?
      <Helmet>
        <title>Einsatzberechnung - meltshop365</title>
        <meta name="description" content="Die meltshop|365 Einsatzberechnung ermittelt die kostenoptimale Zusammenstellung der Einsatz- stoffe einer Charge basierend auf der Zielanalyse nach dem Einschmelzen und den tatsächlichen Be- ständen der Rohstoffe." />
        <meta name="keywords" content="ERP, Industrie 4.0, Schmelz- und Gießbetrieb, Software, ERP-Software, Cloud" />
      </Helmet>
      :
      <Helmet>
        <title>Charge Calculation - meltshop365</title>
        <meta name="description" content="meltshop|365 offers an application to calculate required inputs in order to hit the target of input combinations at minimum cost. This application operates based on target analyses after meltdowns and considering actual stocks of raw material." />
        <meta name="keywords" content="ERP, Industry 4.0, Melting and Casting Operation, Software, ERP Software, Cloud" />
      </Helmet>
      }
    <NavigationBar />
    <div className="bg-image d-flex flex-column justify-content-center align-items-center flex-grow-1">
      <div className="bg-white p-5 rounded mx-5 my-5 w-75">
      {language === 'de' ?<h1 className="my-4 mt-5 h1-grips" id='177'><EditableText id='177'  isH1/></h1> : <h1 className="my-4 mt-5 h1-grips" id='178'><EditableText id='178'  isH1/></h1>}
      {language === 'de' ?<h4 className='h4-grips' id='179'><EditableText id='179'  /></h4> : <h4 className='h4-grips' id='180'><EditableText id='180'  /></h4>}
        <div className="my-4" style={{width: '100px', borderBottom: '10px solid red'}}></div>
        <EinsatzberechnungText />
        <div className='d-flex justify-content-center'>
        <img src={B2} alt="EINSATZBERECHNUNG" className='img-fluid'/>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
}

export default EinsatzberechnungPage;