import React from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import EditableText from './EditableText';

const MerkmaleText: React.FC = () => {

    const { language } = React.useContext(LanguageContext);
    return (
        <div className='text-center'>
            {language === 'en' ?
                <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='71'>
                    <EditableText id='71'  />
                </div></>
                :
                <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='72'>
                    <EditableText id='72'  />
                </div></>
            }
        </div>
    );
}

export default MerkmaleText;