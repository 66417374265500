import React from 'react';
import NavigationBar from '../components/Navbar';
import Footer from '../components/Footer';
import { LanguageContext } from '../contexts/LanguageContext';
import MerkmaleText from '../components/MerkmaleText';
import SvgComponent from '../components/MerkmaleSVG';
import SvgComponentEN from '../components/MerkmaleSVGEN';
import { Helmet } from 'react-helmet';
import EditableText from '../components/EditableText';

const MerkmalePage: React.FC = () => {

  const { language } = React.useContext(LanguageContext);
  return (
    <div className="d-flex flex-column min-vh-100">
      {language === 'de' ?
      <Helmet>
        <title>Merkmale - meltshop365</title>
        <meta name="description" content="meltshop|365 bietet alle benötigten Funktionen, um einen ERP-Fertigungsauftrag ressourceneffizient und ergonomisch auszuführen und die Produktionsdaten auftragsbezogen zu erfassen." />
        <meta name="keywords" content="ERP, Industrie 4.0, Schmelz- und Gießbetrieb, Software, ERP-Software, Cloud" />
      </Helmet>
      :
      <Helmet>
        <title>Features - meltshop365</title>
        <meta name="description" content="meltshop|365 offers all the necessary functions to execute an ERP manufacturing order in a resource-efficient and ergonomic manner and to record production data in an order-related manner." />
        <meta name="keywords" content="ERP, Industrie 4.0, Schmelz- und Gießbetrieb, Software, ERP-Software, Cloud" />
      </Helmet>
    }
    <NavigationBar />
    <div className="bg-image d-flex flex-column justify-content-center align-items-center flex-grow-1">
      <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }} className='flex-grow-1 w-100'>
      <div className="text-center">
      {language === 'de' ? <h1 className="my-4 mt-5 h1-grips" id='69'><EditableText id='69'  isH1/></h1> : <h1 className="my-4 mt-5 h1-grips" id='70'><EditableText id='70'  isH1/></h1>}
            <div className="mx-auto my-4" style={{width: '100px', borderBottom: '10px solid red'}}></div>
        </div>
        <div className='mx-5'><MerkmaleText /></div>
        <div className={`d-flex justify-content-center mt-5 w-50 mx-auto`}>
        {language === 'de' ? <SvgComponent /> : <SvgComponentEN />}
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
}

export default MerkmalePage;