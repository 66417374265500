import React from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import { useNavigate } from 'react-router-dom';
import EditableText from './EditableText';

const ProzessautomatisierungText: React.FC = () => {

    const { language } = React.useContext(LanguageContext);
    const navigate = useNavigate();
    return (
        <div>
            {language === 'en' ?
               <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='195'>
                <EditableText id='195'  />
                <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='196'><EditableText id='196'  /></div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='197'>
            <EditableText id='197'  />
            <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='198'>
            <EditableText id='198'  />
            <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='199'>
            <EditableText id='199'  />
            <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='200'>
            <EditableText id='200'  />
            <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='201'>
            <EditableText id='201'  />
            <br />
            </div>
            <ul>
                <li>
                    <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='202'><EditableText id='202'  /></div>
                </li>
                <li>
                    <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='203'><EditableText id='203'  /></div>
                </li>
                <li>
                    <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='204'><EditableText id='204'  /></div>
                </li>
                <li>
                    <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='205'><EditableText id='205'  /></div>
                </li>
                <li>
                    <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='206'><EditableText id='206'  /></div>
                </li>
                <li>
                    <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='207'><EditableText id='207'  /></div>
                </li>
            </ul>
            <br></br>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='208'>
            <EditableText id='208'  />
            <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='209'>
            <EditableText id='209'  />
            <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='210'>
            <EditableText id='210'  />
              <br></br>
            </div>
            <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => navigate("/merkmale")}>Back to Overview</button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            </>
                :
                <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='211'>
                <EditableText id='211'  />
                <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='212'><EditableText id='212'  /></div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='213'>
            <EditableText id='213'  />
            <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='214'>
            <EditableText id='214'  />
            <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='215'>
            <EditableText id='215'  />
            <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='216'>
            <EditableText id='216'  />
            <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='217'>
            <EditableText id='217'  />
            </div>
            <ul>
                <li>
                    <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='218'><EditableText id='218'  /></div>
                </li>
                <li>
                    <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='219'><EditableText id='219'  /></div>
                </li>
                <li>
                    <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='220'><EditableText id='220'  /></div>
                </li>
                <li>
                    <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='221'><EditableText id='221'  /></div>
                </li>
                <li>
                    <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='222'><EditableText id='222'  /></div>
                </li>
                <li>
                    <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='223'><EditableText id='223'  /></div>
                </li>
            </ul>
            <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='224'>
            <EditableText id='224'  />
            <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='225'>
            <EditableText id='225'  />
            <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='226'>
            <EditableText id='226'  />
              <br></br>
            </div>
            <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => navigate("/merkmale")}>Zurück zur Übersicht</button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            </>
            }
        </div>
    );
}

export default ProzessautomatisierungText;