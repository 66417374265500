import React from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import { useNavigate } from 'react-router-dom';
import EditableText from './EditableText';

const AdministrationText: React.FC = () => {

    const { language } = React.useContext(LanguageContext);
    const navigate = useNavigate();
    return (
        <div>
            {language === 'en' ?
               <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='77'><EditableText id='77'  />
              <br />
            </div>
            <h2 className="h2-grips" id='78'><EditableText id='78'  /></h2>
            <br></br>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='79'><EditableText id='79'  />
            </div>
            <ul>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='80'><EditableText id='80'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='81'><EditableText id='81'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='82'><EditableText id='82'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='83'><EditableText id='83'  /></div>
                   </li>
               </ul>
                <br></br>
                <br></br>
            <h3 className="h2-grips" id='84'><EditableText id='84'  /></h3>
            <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='85'><EditableText id='85'  /></div>
            <ul>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='86'><EditableText id='86'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='87'><EditableText id='87'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='88'><EditableText id='88'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='89'><EditableText id='89'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='90'><EditableText id='90'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='91'><EditableText id='91'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='92'><EditableText id='92'  /></div>
                   </li>
               </ul>
                <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='93'><EditableText id='93'  /><br></br></div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='94'><EditableText id='94'  /></div>
            <br></br>
            <br></br>
            <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => navigate("/merkmale")}>Back to Overview</button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            </>
                :
                <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='95'><EditableText id='95'  />
              <br />
            </div>
            <h2 className="h2-grips" id='96'><EditableText id='96'  /></h2>
            <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='97'><EditableText id='97'  />

            </div>
            <ul>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='98'><EditableText id='98'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='99'><EditableText id='99'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='100'><EditableText id='100'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='101'><EditableText id='101'  /></div>
                   </li>
               </ul>
                <br></br>
                <br></br>
            <h3 className="h2-grips" id='102'><EditableText id='102'  /></h3>
            <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='103'><EditableText id='103'  /></div>
            <ul>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='104'><EditableText id='104'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='105'><EditableText id='105'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='106'><EditableText id='106'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='107'><EditableText id='107'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='108'><EditableText id='108'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='109'><EditableText id='109'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='110'><EditableText id='110'  /></div>
                   </li>
               </ul>
               <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='111'><EditableText id='111'  /><br /></div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='112'><EditableText id='112'  /></div>
            <br></br>
            <br></br>
            <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => navigate("/merkmale")}>Zurück zur Übersicht</button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            </>
            }
        </div>
    );
}

export default AdministrationText;