import React from 'react';
import NavigationBar from '../components/Navbar';
import Footer from '../components/Footer';
import { LanguageContext } from '../contexts/LanguageContext';
import MaterialwirtschaftText from '../components/MaterialwirtschaftText';
import G3 from '../images/g3.svg';
import G3EN from '../images/Material-Management.webp';
import { Helmet } from 'react-helmet';
import EditableText from '../components/EditableText';

const MaterialwirtschaftPage: React.FC = () => {

  const { language } = React.useContext(LanguageContext);
  return (
    <div className="d-flex flex-column min-vh-100">
      {language === 'de' ?
      <Helmet>
        <title>Materialwirtschaft - meltshop365</title>
        <meta name="description" content="Alle Informationen über Lieferungen und den Verbrauch von Einsatz-, Legierungs- und Zuschlagstoffen können über meltshop|365 verwaltet werden. Die Daten zu einer Lieferung werden über die Benutzeroberfläche mit Angaben wie Liefer- nummer, Datum, Liefermenge, Lieferant, Spediteur, Wagon, LKW-Kennzeichen, Analyse laut Lieferschein, Wiegedaten erfasst." />
        <meta name="keywords" content="ERP, Industrie 4.0, Schmelz- und Gießbetrieb, Software, ERP-Software, Cloud" />
      </Helmet>
      :
      <Helmet>
        <title>Material Management - meltshop365</title>
        <meta name="description" content="Information DERXW deliveries and consumption of the charge, alloys and additives can be managed with meltshop|365. The data FRQFHUQLQJ a delivery is recorded in the user interface with inputs such as delivery number, date, quantity, supplier, carrier, wagon, truck license number, analysis according to delivery order, weighing data etc." />
        <meta name="keywords" content="ERP, Industrie 4.0, Schmelz- und Gießbetrieb, Software, ERP-Software, Cloud" />
      </Helmet>
      }
    <NavigationBar />
    <div className="bg-image d-flex flex-column justify-content-center align-items-center flex-grow-1">
      <div className="bg-white p-5 rounded mx-5 my-5 w-75">
      {language === 'de' ?<h1 className="my-4 mt-5 h1-grips" id='153'><EditableText id='153'  isH1/></h1> : <h1 className="my-4 mt-5 h1-grips" id='154'><EditableText id='154'  isH1/></h1>}
      {language === 'de' ?<h4 className='h4-grips' id='155'><EditableText id='155'  /></h4> : <h4 className='h4-grips' id='156'><EditableText id='156'  /></h4>}
        <div className="my-4" style={{width: '100px', borderBottom: '10px solid red'}}></div>
        <MaterialwirtschaftText />
        <div className={`d-flex justify-content-center ${language === 'de' ? 'grips-bg' : ''}`}>
        <img src={language === 'de' ? G3 : G3EN} alt="Materialwirtschaft" className='img-fluid w-50'/>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
}

export default MaterialwirtschaftPage;