import React from 'react';
import NavigationBar from '../components/Navbar';
import Footer from '../components/Footer';
import { LanguageContext } from '../contexts/LanguageContext';
import ProzessautomatisierungText from '../components/ProzessautomatisierungText';
import B3 from '../images/B3.webp';
import G4 from '../images/G4.svg';
import G5 from '../images/G5.svg';
import G4EN from '../images/G4_eng.svg';
import G5EN from '../images/G5_eng.svg';
import { Helmet } from 'react-helmet';
import EditableText from '../components/EditableText';

const ProzessautomatisierungPage: React.FC = () => {

  const { language } = React.useContext(LanguageContext);
  return (
    <div className="d-flex flex-column min-vh-100">
      {language === 'de' ?
      <Helmet>
        <title>Prozessautomatisierung - meltshop365</title>
        <meta name="description" content="Die Module zur intelligenten Prozessautomatisierung von meltshop|365 sichern reproduzierbare Qualität bei gleichzeitig effizientem Ressourceneinsatz.Arbeitspläne, die in Prozessschritte unterteilt sind, geben die metallurgischen Zielgrößen von der Zusammenstellung der Einsatzstoffe, über das Einschmel- zen, die sekundärmetallurgische Behandlung bis hin zum Gießen und Um- schmelzen vor." />
        <meta name="keywords" content="ERP, Industrie 4.0, Schmelz- und Gießbetrieb, Software, ERP-Software, Cloud" />
      </Helmet>
      :
      <Helmet>
        <title>Process automation - meltshop365</title>
        <meta name="description" content="meltshop|365 ensures reproducible quality and efficient use of resources. Process schedules, which are subdivided into process steps, specify the metallurgical command variables. These range from composition of raw material, melting and re-melting, to secondary metallurgical treatments and casting." />
        <meta name="keywords" content="ERP, Industrie 4.0, Schmelz- und Gießbetrieb, Software, ERP-Software, Cloud" />
      </Helmet>
      }
    <NavigationBar />
    <div className="bg-image d-flex flex-column justify-content-center align-items-center flex-grow-1">
      <div className="bg-white p-5 rounded mx-5 my-5 w-75">
      {language === 'de' ?<h1 className="my-4 mt-5 h1-grips" id='191'><EditableText id='191' isH1 /></h1> : <h1 className="my-4 mt-5 h1-grips" id='192'><EditableText id='192'  isH1/></h1>}
          {language === 'de' ?<h4 className='h4-grips' id='193'><EditableText id='193'  /></h4> : <h4 className='h4-grips' id='194'><EditableText id='194'  /></h4>}
        <div className="my-4" style={{width: '100px', borderBottom: '10px solid red'}}></div>
        <ProzessautomatisierungText />
        <div className='d-flex justify-content-center'>
        <img src={B3} alt="PROZESSAUTOMATISIERUNG" className={`img-fluid`}/>
        </div>
        <div className='row'>
        <div className='col-md'>
          <img src={language === 'de' ? G5 : G5EN} alt="PROZESSAUTOMATISIERUNG" className={`img-fluid`}/>
        </div>
        <div className='col-md'>
          <img src={language === 'de' ? G4 : G4EN} alt="PROZESSAUTOMATISIERUNG" className={`img-fluid`}/>
        </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
}

export default ProzessautomatisierungPage;