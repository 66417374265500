import React from 'react';
import NavigationBar from '../components/Navbar';
import Footer from '../components/Footer';
import { LanguageContext } from '../contexts/LanguageContext';
import LizenzierungText from '../components/LizenzierungText';
import { Helmet } from 'react-helmet';
import EditableText from '../components/EditableText';

const LizensierungPage: React.FC = () => {

  const { language } = React.useContext(LanguageContext);
  return (
    <div className="d-flex flex-column min-vh-100">
      {language === 'de' ?
      <Helmet>
        <title>Lizenzierung - meltshop365</title>
        <meta name="description" content="Die Einführung von meltshop|365 erfolgt ohne größere Investitionen und wird nach einem fairen und flexiblen Lizenzmodell vereinbart. Die Lizenzierungskosten richten sich nach Ihren individuellen Anforderungen, dem Grad der Prozessautomatisierung und den gewählten Modulen." />
        <meta name="keywords" content="ERP, Industrie 4.0, Schmelz- und Gießbetrieb, Software, ERP-Software, Cloud" />
      </Helmet>
      :
      <Helmet>
        <title>Licensing - meltshop365</title>
        <meta name="description" content="The installation of meltshop|365 takes place without major investments and will be agreed upon a fair and flexible license model. Licensing costs depend on individual requirements, desired degree of process automation and selected modules." />
        <meta name="keywords" content="ERP, Industry 4.0, Melting and Casting, Software, ERP Software, Cloud" />
      </Helmet>
      }
      <NavigationBar />
      <div className="bg-image d-flex flex-column justify-content-center align-items-center flex-grow-1">
        <div className="bg-white p-5 rounded mx-5 my-5 w-75">
        {language === 'de' ?<h1 className="my-4 mt-5 h1-grips" id='287'><EditableText id='287'  isH1/></h1> : <h1 className="my-4 mt-5 h1-grips" id='288'><EditableText id='288'  isH1/></h1>}
            {language === 'de' ?<h4 className='h4-grips' id='289'><EditableText id='289'  /></h4> : <h4 className='h4-grips' id='290'><EditableText id='290'  /></h4>}
          <div className="my-4" style={{width: '100px', borderBottom: '10px solid red'}}></div>
          <LizenzierungText />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LizensierungPage;