import React from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import { useNavigate } from 'react-router-dom';
import EditableText from './EditableText';

const RohstoffoptimierungText: React.FC = () => {

    const { language } = React.useContext(LanguageContext);
    const navigate = useNavigate();
    return (
        <div>
            {language === 'en' ?
               <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='143'>
               <EditableText id='143'  />
               <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='144'><EditableText id='144'  /></div>
            <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='145'><EditableText id='145'  /></div>
            <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='146'><EditableText id='146'  /></div>
            <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='147'><EditableText id='147'  /></div>
            <br></br>
            <br></br>
            <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => navigate("/merkmale")}>Back to Overview</button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            </>
                :
                <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='148'><EditableText id='148'  />
              <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='149'><EditableText id='149'  /></div>
            <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='150'><EditableText id='150'  /></div>
            <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='151'><EditableText id='151'  /></div>
            <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='152'><EditableText id='152'  /></div>
            <br></br>
            <br></br>
            <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => navigate("/merkmale")}>Zurück zur Übersicht</button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            </>
            }
        </div>
    );
}

export default RohstoffoptimierungText;