import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/Home';
import SchmelzplanungPage from './pages/Schmelzplanung';
import MaterialwirtschaftPage from './pages/Materialwirtschaft';
import NewsPage from './pages/News';
import ProcessvarsPage from './pages/Processvars';
import AdministrationPage from './pages/Administration';
import AuftragsabrechnungPage from './pages/Auftragsabrechnung';
import ControllingPage from './pages/Controlling';
import EinsatzberechnungPage from './pages/Einsatzberechnung';
import KontaktPage from './pages/Kontakt';
import LizenzierungPage from './pages/Lizenzierung';
import MerkmalePage from './pages/Merkmale';
import ProduktionsplanungPage from './pages/Produktionsplanung';
import ProzessautomatisierungPage from './pages/Prozessautomatisierung';
import RohstoffoptimierungPage from './pages/Rohstoffoptimierung';
import DatenschutzPage from './pages/Datenschutz';
import ImpressumPage from './pages/Impressum';
import SystemPage from './pages/System';
import { LanguageProvider } from './contexts/LanguageContext';
import { AuthProvider} from './contexts/LoginContext';
import Login from './components/Login';
import './css/grips.css';

const App: React.FC = () => {
  <Route path="/" element={<HomePage />} />
  return (
    <AuthProvider>
    <LanguageProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/schmelzplanung" element={<SchmelzplanungPage />} />
          <Route path="/materialwirtschaft" element={<MaterialwirtschaftPage />} />
          {/*<Route path="/news" element={<NewsPage />} /> */}
          <Route path="/processvars" element={<ProcessvarsPage />} />
          <Route path="/administration" element={<AdministrationPage />} />
          <Route path="/auftragsabrechnung" element={<AuftragsabrechnungPage />} />
          <Route path="/controlling" element={<ControllingPage />} />
          <Route path="/einsatzberechnung" element={<EinsatzberechnungPage />} />
          <Route path="/kontakt" element={<KontaktPage />} />
          <Route path="/lizenzierung" element={<LizenzierungPage />} />
          <Route path="/merkmale" element={<MerkmalePage />} />
          <Route path="/produktionsplanung" element={<ProduktionsplanungPage />} />
          <Route path="/prozessautomatisierung" element={<ProzessautomatisierungPage />} />
          <Route path="/rohstoffoptimierung" element={<RohstoffoptimierungPage />} />
          <Route path="/datenschutz" element={<DatenschutzPage />} />
          <Route path="/impressum" element={<ImpressumPage />} />
          <Route path="/system" element={<SystemPage />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
    </LanguageProvider>
    </AuthProvider>
  );
}

export default App;