import React, { useState, useEffect } from 'react';
import EditableText from '../components/EditableText';
import NavigationBar from '../components/Navbar';
import Footer from '../components/Footer';
import { LanguageContext } from '../contexts/LanguageContext';
import AuftragsabrechnungText from '../components/AuftragsabrechnungText';
import G6 from '../images/G6.svg';
import G6EN from '../images/G6_eng.svg';
import { Helmet } from 'react-helmet';

const AuftragsabrechnungPage: React.FC = () => {

  const { language } = React.useContext(LanguageContext);
  const [imgClass, setImgClass] = useState(window.innerWidth <= 1024 ? 'w-100' : 'w-50');

  useEffect(() => {
    const handleResize = () => {
      setImgClass(window.innerWidth <= 1024 ? 'w-100' : 'w-50');
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className="d-flex flex-column min-vh-100">
      {language === 'de' ?
        <Helmet>
        <title>Auftragsabrechnung - meltshop365</title>
        <meta name="description" content="Zur Abrechnung des Fertigungsauftrages steht in meltshop|365 ein Modul zur Verfü- gung, mit dem alle Daten einer Charge eingesehen und ggf. korrigiert werden können. Soweit die Chargendaten den Sollvorgaben entsprechen, kann eine analytische und metallurgische Freigabe erfolgen." />
        <meta name="keywords" content="ERP, Industrie 4.0, Schmelz- und Gießbetrieb, Software, ERP-Software, Cloud" />
      </Helmet>
      :
      <Helmet>
        <title>Order Accounting - meltshop365</title>
        <meta name="description" content="meltshop|365 offers a module to access data concerning a charge and if necessary, correct defective data, in order to account a manufacturing order. If the data is in accordance with target specifications, the billing of the order can be approved analytically and metallurgically." />
        <meta name="keywords" content="ERP, Industry 4.0, Melting and casting operation, Software, ERP software, Cloud" />
      </Helmet>
      }
    <NavigationBar />
    <div className="bg-image d-flex flex-column justify-content-center align-items-center flex-grow-1">
      <div className="bg-white p-5 rounded mx-5 my-5 w-75">
      {language === 'de' ?<h1 className="my-4 mt-5 h1-grips" id='239'><EditableText id='239'  isH1/></h1> : <h1 className="my-4 mt-5 h1-grips" id='240'><EditableText id='240'  isH1/></h1>}
          {language === 'de' ?<h4 className='h4-grips' id='241'><EditableText id='241'  /></h4> : <h4 className='h4-grips' id='242'><EditableText id='242'  /></h4>}
        <div className="my-4" style={{width: '100px', borderBottom: '10px solid red'}}></div>
        <AuftragsabrechnungText />
        <div className='d-flex justify-content-center'>
        <img src={language === 'de' ? G6 : G6EN} alt="Schmelzauftrag" className={`img-fluid ${imgClass}`}/>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
}

export default AuftragsabrechnungPage;