import React from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import EditableText from './EditableText';

const SystemText: React.FC = () => {

    const { language } = React.useContext(LanguageContext);
    return (
        <div>
            {language === 'en' ?
               <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='251'>
               <EditableText id='251'  />
              <br />
            </div><h2 className='h2-grips' id='252'><EditableText id='252' isH2/></h2>
            <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='253'><EditableText id='253'  /></div>
            <br></br>
            <br></br>
            <h3 className='h2-grips' id='254'><EditableText id='254'  isH2/></h3>
            <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='255'><EditableText id='255'  /></div>
            <br></br>
            <br></br>
            <h4 className='h2-grips' id='256'><EditableText id='256'  isH2/></h4>
            <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='257'><EditableText id='257'  /></div>
            <br></br>
            <br></br>
            </>
                :
                <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='258'>
                     <EditableText id='258'  />
                <br />
                </div><h2 className='h2-grips' id='259'><EditableText id='259'  isH2/></h2>
                <br />
                <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='260'><EditableText id='260'  /></div>
                <br></br>
                <br></br>
                <h3 className='h2-grips' id='261'><EditableText id='261'  isH2/></h3>
                <br />
                <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='262'><EditableText id='262'  /></div>
                <br></br>
                <br></br>
                <h4 className='h2-grips' id='263'><EditableText id='263'  isH2/></h4>
                <br />
                <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='264'><EditableText id='264'  /></div>
                <br></br>
                <br></br>
                </>
            }
        </div>
    );
}

export default SystemText;