import React from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import { useNavigate } from 'react-router-dom';
import EditableText from './EditableText';

const ProduktionsplanungText: React.FC = () => {

    const { language } = React.useContext(LanguageContext);
    const navigate = useNavigate();
    return (
        <div>
            {language === 'en' ?
               <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='117'><EditableText id='117'  />
              <br></br>
            </div>
            <ul>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='118'><EditableText id='118'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='119'><EditableText id='119'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='120'><EditableText id='120'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='121'><EditableText id='121'  /></div>
                   </li>
               </ul>
                <br></br>
                <br></br>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='122'><EditableText id='122'  /><br></br></div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='123'><EditableText id='123'  /><br></br></div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='124'><EditableText id='124'  /><br></br></div>
            <ol className='grips-ol'>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='125'><EditableText id='125'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='126'><EditableText id='126'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='127'><EditableText id='127'  /></div>
                   </li>
               </ol>
            <br></br>
            <br></br>
            <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => navigate("/merkmale")}>Back to Overview</button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            </>
                :
                <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='128'><EditableText id='128'  />
                  <br></br>
                </div>
                <ul>
                       <li>
                           <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='129'><EditableText id='129'  /></div>
                       </li>
                       <li>
                           <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='130'><EditableText id='130'  /></div>
                       </li>
                       <li>
                           <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='131'><EditableText id='131'  /></div>
                       </li>
                       <li>
                           <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='132'><EditableText id='132'  /></div>
                       </li>
                   </ul>
                    <br></br>
                    <br></br>
                <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='133'><EditableText id='133'  /><br></br></div>
                <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='134'><EditableText id='134'  /><br></br></div>
                <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='135'><EditableText id='135'  /><br></br></div>
                <ol className='grips-ol'>
                       <li>
                           <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='136'><EditableText id='136'  /></div>
                       </li>
                       <li>
                           <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='137'><EditableText id='137'  /></div>
                       </li>
                       <li>
                           <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='138'><EditableText id='138'  /></div>
                       </li>
                   </ol>
                <br></br>
                <br></br>
                <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => navigate("/merkmale")}>Zurück zur Übersicht</button>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                </>
            }
        </div>
    );
}

export default ProduktionsplanungText;