import React from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import EditableText from './EditableText';

const ProcessvarsText: React.FC = () => {

    const { language } = React.useContext(LanguageContext);
    return (
        <div>
            {language === 'en' ?
               <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='269'>
               <EditableText id='269'  />
               <br></br>
               <br></br>
           </div><h2 className='h2-grips' id='270'><EditableText id='270'  isH2/></h2>
           <br></br>
           <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='271'><EditableText id='271'  /></div>
           <ul>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='272'><EditableText id='272'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='273'><EditableText id='273'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='274'><EditableText id='274'  /></div>
                   </li>
                   <li>
                       <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='275'><EditableText id='275'  /></div>
                   </li>
               </ul>
               <br></br>
               <br></br>
               <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='276'><EditableText id='276'  /></div>
               <br></br>
               </>
                :
                <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='277'>
                    <EditableText id='277'  />
                    <br></br>
                    <br></br>
                </div><h2 className='h2-grips' id='278'><EditableText id='278'  isH2/></h2>
                <br></br>
                <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='279'><EditableText id='279'  /></div>
                <ul>
                        <li>
                            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='280'><EditableText id='280'  /></div>
                        </li>
                        <li>
                            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='281'><EditableText id='281'  /></div>
                        </li>
                        <li>
                            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='282'><EditableText id='282'  /></div>
                        </li>
                        <li>
                            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='283'><EditableText id='283'  /></div>
                        </li>
                        <li>
                            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='284'><EditableText id='284'  /></div>
                        </li>
                        <li>
                            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='285'><EditableText id='285'  /></div>
                        </li>
                    </ul>
                    <br></br>
                    <br></br>
                    <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='286'><EditableText id='286'  /></div>
                    <br></br>
                    </>
            }
        </div>
    );
}

export default ProcessvarsText;