import React from 'react';
import NavigationBar from '../components/Navbar';
import Footer from '../components/Footer';
import { LanguageContext } from '../contexts/LanguageContext';
import ProduktionsplanungText from '../components/ProduktionsplanungText';
import PP from '../images/Produktionsplanung.webp';
import PPEN from '../images/Production-Planning-1.webp';
import { Helmet } from 'react-helmet';
import EditableText from '../components/EditableText';

const ProduktionsplanungPage: React.FC = () => {

  const { language } = React.useContext(LanguageContext);
  return (
    <div className="d-flex flex-column min-vh-100">
      {language === 'de' ?
      <Helmet>
        <title>Produktionsplanung - meltshop365</title>
        <meta name="description" content="Zur Produktionsplanung werden Fertigungsaufträge eines ERP Systems (Enterprise Resource Planning z. B. SAP, ORACLE, Microsoft, INFOR, ...) in meltshop|365 importiert. Fertigungsaufträge haben eine eindeutige Auftragsnummer und bestimmen" />
        <meta name="keywords" content="ERP, Industrie 4.0, Schmelz- und Gießbetrieb, Software, ERP-Software, Cloud" />
      </Helmet>
      :
      <Helmet>
        <title>Production Planning - meltshop365</title>
        <meta name="description" content="For the purpose of production planning, data concerning manufacturing orders can be imported from an ERP System (SAP, ORACLE, Microsoft, INFOR, ...) into meltshop|365. A production order has a defined ID/order number and gives access to following information" />
        <meta name="keywords" content="ERP, Industrie 4.0, Schmelz- und Gießbetrieb, Software, ERP-Software, Cloud" />
      </Helmet>
    }
    <NavigationBar />
    <div className="bg-image d-flex flex-column justify-content-center align-items-center flex-grow-1">
      <div className="bg-white p-5 rounded mx-5 my-5 w-75">
      {language === 'de' ?<h1 className="my-4 mt-5 h1-grips" id='113'><EditableText id='113'  isH1/></h1> : <h1 className="my-4 mt-5 h1-grips" id='114'><EditableText id='114'  isH1/></h1>}
      {language === 'de' ? <h4 className='h4-grips' id='115'><EditableText id='115'  /></h4> : <h4 className='h4-grips' id='116'><EditableText id='116'  /></h4>}
        <div className="my-4" style={{width: '100px', borderBottom: '10px solid red'}}></div>
        <ProduktionsplanungText />
        <div className={`d-flex justify-content-center`}>
        <img src={language === 'de' ? PP : PPEN} alt="Materialwirtschaft" className='img-fluid'/>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
}

export default ProduktionsplanungPage;