import React from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import EditableText from './EditableText';

const DatenschutzText: React.FC = () => {

  const { language } = React.useContext(LanguageContext);
  return (
      <div>
        {language === 'de' ?
              <div className='text-white' id='315'>
                  <EditableText id='315'  />
              </div>
          :
              <div className='text-white' id='316'>
                  <EditableText id='316'  />
              </div>
          }
      </div>
  );
}

export default DatenschutzText;