import React, { Dispatch, SetStateAction, useEffect } from 'react';

export const LanguageContext = React.createContext({ language: 'de', setLanguage: {} as Dispatch<SetStateAction<string>> });

export const LanguageProvider = ({ children }: { children: React.ReactNode }) => {
    const [language, setLanguage] = React.useState(localStorage.getItem('language') || 'de');

    useEffect(() => {
        localStorage.setItem('language', language);
      }, [language]);

    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};