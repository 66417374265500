import React from 'react';
import NavigationBar from '../components/Navbar';
import Footer from '../components/Footer';
import { LanguageContext } from '../contexts/LanguageContext';
import ImpressumText from '../components/ImpressumText';
import { Helmet } from 'react-helmet';
import EditableText from '../components/EditableText';

const ImpressumPage: React.FC = () => {

  const { language } = React.useContext(LanguageContext);
  return (
    <div className='grips-bg d-flex flex-column min-vh-100'>
      {language === 'de' ?
      <Helmet>
        <title>Impressum - meltshop365</title>
      </Helmet>
      :
      <Helmet>
        <title>Imprint - meltshop365</title>
      </Helmet>
      }
      <NavigationBar />
      <div className='grips-margin-left flex-grow-1'>
      {language === 'de' ?<h1 className="my-4 mt-5 text-white" id='309'><EditableText id='309'  isH1/></h1> : <h1 className="my-4 mt-5 text-white" id='310'><EditableText id='310'  isH1/></h1>}
        <div className="my-4" style={{width: '100px', borderBottom: '10px solid red'}}></div>
        <ImpressumText />
      </div>
      <Footer />
    </div>
  );
}

export default ImpressumPage;