import React from 'react';
import NavigationBar from '../components/Navbar';
import Footer from '../components/Footer';
import { LanguageContext } from '../contexts/LanguageContext';
import AdministrationText from '../components/AdministrationText';
import admin from '../images/Administration.webp';
import { Helmet } from 'react-helmet';
import EditableText from '../components/EditableText';

const AdministrationPage: React.FC = () => {

  const { language } = React.useContext(LanguageContext);
  return (
    <div className="d-flex flex-column min-vh-100">
      {language === 'de' ?
      <Helmet>
        <title>Administration - meltshop365</title>
        <meta name="description" content="Ein Alleinstellungsmerkmal von meltshop|365 gegenüber herkömmlichen Systemen ist die weitestgehende Konfigurierbarkeit. Über die Benutzeroberfläche können von Administratoren unter anderem konfiguriert werden" />
        <meta name="keywords" content="ERP, Industrie 4.0, Schmelz- und Gießbetrieb, Software, ERP-Software, Cloud" />
      </Helmet>
      :
      <Helmet>
        <title>Administration - meltshop365</title>
        <meta name="description" content="One unique feature of meltshop|365 compared to conventional systems is its extensive configurability. Administrators can configure, among other things, via the user interface" />
        <meta name="keywords" content="ERP, Industrie 4.0, Schmelz- und Gießbetrieb, Software, ERP-Software, Cloud" />
      </Helmet>
    }
    <NavigationBar />
    <div className="bg-image d-flex flex-column justify-content-center align-items-center flex-grow-1">
      <div className="bg-white p-5 rounded mx-5 my-5 w-75">
      {language === 'de' ? <h1 className="my-4 mt-5 h1-grips" id='73'><EditableText id='73'  isH1/></h1> : <h1 className="my-4 mt-5 h1-grips" id='74'><EditableText id='74'  isH1/></h1>}
      {language === 'de' ? <h4 className='h4-grips' id='75'><EditableText id='75'  /></h4> : <h4 className='h4-grips' id='76'><EditableText id='76'  /></h4>}
        <div className="my-4" style={{width: '100px', borderBottom: '10px solid red'}}></div>
        <AdministrationText />
        <div className={`d-flex justify-content-center`}>
        <img src={admin} alt="Administration" className='img-fluid'/>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
}

export default AdministrationPage;