import React from 'react';
import NavigationBar from '../components/Navbar';
import Footer from '../components/Footer';
import { LanguageContext } from '../contexts/LanguageContext';
import DatenschutzText from '../components/DatenschutzText';
import { Helmet } from 'react-helmet';
import EditableText from '../components/EditableText';

const DatenschutzPage: React.FC = () => {

  const { language } = React.useContext(LanguageContext);
  return (
    <div className='grips-bg'>
      {language === 'de' ?
      <Helmet>
        <title>Datenschutz - meltshop365</title>
      </Helmet>
      :
      <Helmet>
        <title>Data Protection - meltshop365</title>
      </Helmet>
      }
      <NavigationBar />
      <div className='grips-margin-left'>
      {language === 'de' ?<h1 className="my-4 mt-5 text-white" id='313'><EditableText id='313'  isH1/></h1> : <h1 className="my-4 mt-5 text-white" id='314'><EditableText id='314'  isH1/></h1>}
        <div className="my-4" style={{width: '100px', borderBottom: '10px solid red'}}></div>
        <DatenschutzText />
      </div>
      <Footer />
    </div>
  );
}

export default DatenschutzPage;