import React from 'react';
import { LanguageContext } from '../contexts/LanguageContext';
import { useNavigate } from 'react-router-dom';
import EditableText from './EditableText';

const ControllingText: React.FC = () => {

    const { language } = React.useContext(LanguageContext);
    const navigate = useNavigate();
    return (
        <div>
            {language === 'en' ?
               <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='231'>
               <EditableText id='231'  />
              <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='232'><EditableText id='232'  /></div>
            <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='233'>
            <EditableText id='233'  />
            <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='234'>
            <EditableText id='234'  />
            <br />
            </div>
            <br></br>
            <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => navigate("/merkmale")}>Back to Overview</button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            </>
                :
                <><div className='text-grips fs-4 fs-md-3 fs-lg-2' id='235'>
               <EditableText id='235'  />
               <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='236'><EditableText id='236'  /></div>
            <br />
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='237'>
            <EditableText id='237'  />
            <br />
            </div>
            <div className='text-grips fs-4 fs-md-3 fs-lg-2' id='238'>
            <EditableText id='238'  />
            <br />
            </div>
            <br></br>
            <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => navigate("/merkmale")}>Zurück zur Übersicht</button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            </>
            }
        </div>
    );
}

export default ControllingText;